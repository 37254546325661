<template>
    <r-e-dialog title="" show-footer :visible.sync="dialogVisible" top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="套内面积" prop="">
                    <el-input v-model="formPublish.area" placeholder="请输入内容" style="width: 200px;">
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {apartmentSonUpdateApi} from '@/api/guaranteed-rental-housing-resources';
import {MessageSuccess} from "@custom/message";

export default {
    name: "layer-save-inner",
    data() {
        return {
            dialogVisible: false,
            type: "",
            formPublish: {
                uuid: null,
                area: null,
            },
            rules: {
                area: [{required: true, message: '请输入面积', trigger: 'blur'}],
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    props: {},
    components: {},
    methods: {
        openDialog(type, data) {
            this.type = type;
            this.dialogVisible = true;
            if (type === 'edit') {
                const oldData = this.formPublish;
                const {areaIn:area} = data;
                this.formPublish = {...oldData, ...data, area};
            }
        },

        clickSubmit() {
            let that = this;
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    const {type} = this;
                    const {uuid, area} = this.formPublish;
                    const data = {area};

                    if (type === 'edit') data.uuid = uuid;

                    if (type === 'edit') that.apartmentSonUpdate(data)
                }
            });
        },

        clickCancel(closeType = "cancel") {
            this.formPublish = {uuid: null, area: null,};
            this.dialogVisible = false;
            if (closeType === "update") this.$emit('handleChange');
        },

        /*readyApartmentSave(data) {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            readyApartmentSave(data).then(res => {
                MessageSuccess("添加成功");
                that.clickCancel();
            }).finally(() => loading.close());
        },*/

        apartmentSonUpdate(data) {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            apartmentSonUpdateApi(data).then(res => {
                MessageSuccess("修改成功");
                that.clickCancel('update');
            }).finally(() => loading.close());
        },
    },
    mounted() {
    },
}
</script>

<style scoped>

</style>